import { Injectable } from "@angular/core";
import { BaseCrudService } from "@shared/services/base-crud.service";
import { Observable } from "rxjs";
import { DataList } from "@data/schemas/filter/data-list";
import { StockLocation } from "@data/schemas/stock-location";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { defaultHttpParamsConfig } from "@shared/utils/datatable-utils";
import { convertHttpParamsToQueryString } from "@shared/utils/filter-params-utils";

@Injectable({
	providedIn: "root"
})
export class StockLocationService {
	readonly STOCK_LOCATIONS_ENDPOINT = "stock-locations";

	constructor(
		private data: BaseCrudService,
		private dataBff: BaseCrudBffService
	) {}

	getAllStockLocationsBff(
		httpParamsConfig = defaultHttpParamsConfig()
	): Observable<DataList<StockLocation>> {
		const params = convertHttpParamsToQueryString({
			...httpParamsConfig,
			size: 10
		});
		return this.dataBff.getAll<DataList<StockLocation>>({
			path: this.STOCK_LOCATIONS_ENDPOINT,
			params
		});
	}

	getStockLocationsWithBalanceByProductBff(
		productId: number
	): Observable<StockLocation[]> {
		return this.dataBff.getAll({
			path: `${this.STOCK_LOCATIONS_ENDPOINT}/list-with-balance-by-product/${productId}`
		});
	}

	getStockLocationsById(id: number): Observable<StockLocation> {
		return this.data.getById<StockLocation>(
			this.STOCK_LOCATIONS_ENDPOINT,
			id
		);
	}

	saveStockLocations(
		stock: Partial<StockLocation>
	): Observable<Partial<StockLocation>> {
		return this.data.save(this.STOCK_LOCATIONS_ENDPOINT, stock);
	}

	deleteStockLocations(id: number): Observable<unknown> {
		return this.data.delete(this.STOCK_LOCATIONS_ENDPOINT, id);
	}
}
