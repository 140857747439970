import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { BaseCrudService } from "@shared/services/base-crud.service";
import { HttpParamsConfig } from "@shared/schemas/http-params-config";
import { DataList } from "@data/schemas/filter/data-list";
import { Product } from "@data/schemas/product";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";

@Injectable({
	providedIn: "root"
})
export class ProductService {
	private readonly PRODUCT_ENDPOINT = "products";

	constructor(
		private data: BaseCrudService,
		private dataBff: BaseCrudBffService
	) {}

	getAllProduct(
		httpParamsConfig?: HttpParamsConfig
	): Observable<DataList<Product>> {
		const params = this.dataBff.getAllFiltersParams(httpParamsConfig);
		return this.dataBff.getAll<DataList<Product>>({
			path: this.PRODUCT_ENDPOINT,
			params
		});
	}

	getProductById(id: number): Observable<Product> {
		return this.data.getById<Product>(this.PRODUCT_ENDPOINT, id);
	}

	saveProduct(
		product: Pick<Product, "codigoInterno" | "descricao"> & {
			unidEntrada?: string;
			id?: string;
		}
	): Observable<Product> {
		return this.dataBff
			.save({ path: this.PRODUCT_ENDPOINT, object: product })

			.pipe(map((result) => result as unknown as Product));
	}

	deleteProduct(id: number): Observable<unknown> {
		return this.data.delete(this.PRODUCT_ENDPOINT, id);
	}

	searchProducts(searchText: string): Observable<Product[]> {
		return this.dataBff.getAll({
			path: this.PRODUCT_ENDPOINT,
			params: { q: searchText }
		});
	}
}
